import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ExternalLink = makeShortcode("ExternalLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h4>{`Description`}</h4>
    <p>{`Innometeo is a system for the generation and visualization of
weather related data. It is targeted at meteorologists and other technical
staff of the portuguese weather (`}<ExternalLink href="http://www.ipma.pt/en/" mdxType="ExternalLink">{`IPMA`}</ExternalLink>{`)
and civil protection (`}<ExternalLink href="http://www.prociv.pt/en-us/" mdxType="ExternalLink">{`ANEPC`}</ExternalLink>{`)
national authorities.`}</p>
    <p>{`While working at `}<ExternalLink href="http://likeno.pt" mdxType="ExternalLink">{`Likeno`}</ExternalLink>{`,
I was part of the team that developed Innometeo. The system gathers a set of
meteorological data products from multiple sources, processes this data into
a common format and makes the data products available as a set of collections.`}</p>
    <h4>{`My role`}</h4>
    <p>{`My role on the project was the design and implementation of the backend
components, which consist of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`A set of processing lines that deal with multiple input raster data and
its tranformation into output multidimensional netcdf files for various
collections of meteorological products. Processing lines were implemented
with airflow and the system is distributed over several worker nodes by leveraging
rabbitmq as a message broker`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A geospatial server that is able to serve all generated products according
to OGC WMS standard. This is implemented with geospaca (more on that below),
which is based on MapServer and adds an API layer on top of it.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A service that provides historical information on each collection for
sampled points by inspecting data on-the-fly.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      